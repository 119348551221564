import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedRateOptions from "../components/Repeating/RelatedRateOptions";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Adjustable Rate Mortgage | Right Start | Mortgage Lender"
        description="Learn about the benefits of an adjustable-rate mortgage (ARM), where the interest rate on your home loan changes with the market. Call today to request rates!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/3.0 Mortgage Rate Options/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[640px]">
            <h1 className="text-white">Adjustable-Rate Mortgage (ARM)</h1>
            <p className="mb-0 text-white">
              If you need a lower monthly mortgage payment to start with (you’re
              a first-time home buyer, for example, or you expect your income to
              increase over time), an adjustable-rate mortgage can be a great
              choice.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="What is it?"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="How it works"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Timeframe"
                  stripHash
                />
              </li>

              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Examples"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  What is an Adjustable ARM Mortgage?
                </h2>
                <p className="mb-0">
                  With an adjustable-rate mortgage, there is an initial loan
                  period where you pay a fixed rate. Then the interest rate
                  changes periodically throughout the remainder of the loan. The
                  interest rate change depends on how the market has fluctuated
                  at that time.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Does an Adjustable ARM Mortgage Work?
                </h2>
                <p className="mb-0">
                  Typically, there is an initial fixed-rate period of 5 to 10
                  years. The interest rate may adjust each year after that
                  through the end of the loan term. A 5-year or 10-year ARM
                  offers a lower mortgage rate than a traditional 30-year fixed
                  loan. Adjustable-rate mortgages are a great option for those
                  who plan on selling or refinancing their home before the five
                  or ten years are up.
                </p>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Long Does an Adjustable ARM Mortgage Last?
                </h2>
                <p className="mb-0">
                  An adjustable-rate mortgage lasts for the entire length of
                  your loan. The initial rate and payment amount on an ARM are
                  in effect for a month to five years or more, depending on your
                  terms.
                </p>
              </div>

              <div
                id="section-4"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  Types of Adjustable ARM Mortgage Loans
                </h2>
                <ul className="styled-list-checkmark">
                  <li>
                    5/1 ARM - Fixed rate for the first 5 years. Adjusts annually
                    for the remaining years.
                  </li>
                  <li>
                    10/1 ARM - Fixed rate for the first 10 years. Adjusts
                    annually for the remaining years.
                  </li>
                  <li>
                    5/6 ARM - Fixed rate for the first 5 years. Adjusts every 6
                    months for the remaining years.
                  </li>
                  <li>
                    7/6 ARM - Fixed rate for the first 7 years. Adjusts every 6
                    months for the remaining years.
                  </li>
                  <li>
                    10/6 ARM - Fixed rate for the first 10 years. Adjusts every
                    6 months for the remaining years.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <ValueProps />
      <About />
      <RelatedRateOptions hiddenRate={2} className="grid-cols-3" />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
